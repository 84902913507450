@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;800;900&display=swap');

@font-face {
  font-family: 'BMDOHYEON';
  src: url('../assets/fonts/BMDOHYEON.ttf');
}

body {
  font-family: 'Montserrat', 'BMDOHYEON', sans-serif;
}

.en {
  font-family: 'Montserrat' !important;
}

.kr {
  font-family: 'BMDOHYEON' !important;
}

/* FOR ANIMATION    */
.iconQandA {
  width: inherit;
  height: inherit;
  position: relative;
  transform-style: preserve-3d;
  /* Enables 3D transforms for children */
  cursor: pointer;
}

.iconQandA-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Hides the back face when not visible */
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconQandA-back {
  transform: rotateY(180deg);
  /* Back face starts flipped */
}
